<template>
  <v-row class="justify-center align-center">
    <v-col
      cols="12"
    >
      <v-card flat>
        <div class="pa-5">
          <!--          <h6 class="mb-4 text-h5">
            {{ $t('Photos de l\'article') }}
          </h6>-->
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <div style="display: flex; justify-content: center;">
                <vue-upload-multiple-image
                  :data-images="images"
                  :max-image="12"
                  :popup-text="$t('Photos de l\'article')"
                  :drop-text="$t('Télécharger des photos')"
                  :primary-text="$t('Photos de l\'article')"
                  :mark-is-primary-text="$t('Photo principale')"
                  :browse-text="$t('Télécharger')"
                  :drag-text="$t('Photos de l\'article')"
                  @upload-success="uploadImageSuccess"
                  @before-remove="beforeRemove"
                  @edit-image="editImage"
                  @mark-is-primary="orderImages"
                />
              </div>
            </v-col>
          </v-row>
        </div>
        <v-snackbar v-model="isSnackbarBottomVisible">
          {{ snackBarMessage }}

          <template v-slot:action="{ attrs }">
            <v-btn
              :color="snackBarColor"
              text
              v-bind="attrs"
              @click="isSnackbarBottomVisible = false"
            >
              {{ $t("Fermer") }}
            </v-btn>
          </template>
        </v-snackbar>
        <v-dialog
          v-model="loading"
          persistent
          width="70"
          class="overflow-hidden transparent"
        >
          <v-card
            width="70"
            height="70"
            class="overflow-hidden"
          >
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="fill-height align-self-center"
            >
              <v-col
                cols="12"
                class="text-center"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="mx-auto"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <slot name="actions" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import { mapGetters, mapActions, mapState } from 'vuex'

  import VueUploadMultipleImage from 'vue-upload-multiple-image'
  import { i18n } from '../../plugins/i18n'

  export default {
    components: {
      VueUploadMultipleImage,
    },
    props: {
      value: { type: [Object, Array], default: () => { return { images: [] } } },
    },
    data () {
      return {
        step: 0,
        tab: null,
        showWarning: false,
        productForm: null,
        validAddressForm: false,
        loading: false,
        loading_points: false,
        loading_cities: false,
        create_new: false,
        security_tax: 0,
        commission_minimal: 0,
        formData: null,
        fileList: [],
        donate: 0,
        details: {},
        form: {},
        usercountry: 0,
        tvas: [],
        transporters: [],
        system_transporters: [],
        shipment_methods: [],
        payment_methods: [],
        relay_points: [],
        packages: [],
        images: [],
        package: {},
        errors: {
          firstname: false,
          lastname: false,
          email: false,
          phone: false,
          country: false,
          address: false,
          zip: false,
          city: false,
        },

        errorMessages: { name: 'Est requis' },
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        telRules: [v => !!v || i18n.t('Le numéro de téléphone est requis.')],
        addressRules: [v => !!v || i18n.t('L\'adresse est requise.')],
        introRules: [v => !!v || i18n.t('L\'introduction est requise.')],
        nameRules: [v => !!v || i18n.t('Le Nom du produit est requise.')],
        publicRules: [v => !!v || i18n.t('Le Prix public est requise.')],
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        radios: null,
        radiosTwo: null,
        cart: [
        ],
        product: {
          images: [],
        },
        order: {
          user: {
            country_code: null,
          },
          country_code: null,
          city: null,
          post_code: null,
        },
        addresses: [],
        deliveryaddress: [],
        snackBarMessage: '',
        snackBarColor: null,
        isSnackbarBottomVisible: false,

      }
    },
    computed: {
      ...mapState({
        user: 'user',
        displayNotification: 'display_notification',
      }),
      cartTotal () {
        let total = 0
        this.getCartProducts.forEach(product => {
          total += product.amount * product.qty
        })
        return total
      },
      backButtonLabel () {
        return this.$t('Précédent')
      },
      nextButtonLabel () {
        if (this.step === 0) {
          return this.$t('Suivant')
        }
        if (this.step === 1) {
          return this.$t('Procéder au Categories')
        }
        if (this.step === 2) {
          return this.$t('Procéder a la publication')
        }
        return this.$t('Suivant' + this.step)
      },
      finishButtonLabel () {
        if (this.product && this.product.active) {
          return this.$t('Deactiver')
        }
        return this.$t('Publier')
      },
      productImages () {
        return this.product.images.map((e) => { return { ...e, path: e.image } })
      },
    },
    mounted () {

    },
    created () {
      this.product = this.value || { images: [] }
      this.images = this.productImages
    },
    methods: {

      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
      uploadImageSuccess (formData, index, fileList) {
        this.formData = formData
        this.fileList = fileList

        this.submitImage(formData, false, index)
        // const data = new FormData()
        /* for (const file of fileList) {
            this.product.photos.push(file)
           // data.append(this.inputname, file, file.name)
          } */

        // return data
        // console.log('data', formData, index, fileList)
        // Upload image api
        // axios.post('http://your-url-upload', formData).then(response => {
        //   console.log(response)
        // })
      },
      beforeRemove (index, done, fileList) {
        console.log('index', index, fileList)
        var r = confirm('Êtes-vous sûr de supprimer l\'image ?')
        if (r == true) {
          if (this.product && this.product.id && this.product.media && this.product.media.length > 0) {
            this.product.images.splice(index, 1)
            this.product.media.splice(index, 1)
            // eslint-disable-next-line no-undef
            axios.post(`/product/order_photos/${this.product.id}`, { images: this.product.images.map((e) => e.id) }).then((res) => {})
          }
          done()
        } else {
        }
      },
      editImage (formData, index, fileList) {
        this.formData = formData
        this.fileList = fileList

        this.submitImage(formData, false, index)
        console.log('edit data', formData, index, fileList)
      },
      submitImage (form, saved = false, index = -1) {
        if (!this.product.id && this.form) return
        // const url = "admin/customer/photo/" + this.customer.id;
        // upload data to the server
        //    this.currentStatus = STATUS_SAVING;
        // this.$emit('input', null);

        this.formData = null

        this.loading = true
        axios.post(`/product/store_photo/${this.product.id}`, form)
          .then(response => {
            // this.product.images.push(response.data)
            // this.product.media = this.product.media || []
            // this.product.media.push(response.data)
            if(index >= 0) {
              this.product.images[index] = {
                ...response.data,
                img: response.data.image || require('@/assets/images/icons/picture.svg'),
                image: response.data.image || require('@/assets/images/icons/picture.svg'),
                thumb: response.data.thumb || require('@/assets/images/icons/picture.svg'),
              }
            }
            if (saved) {
              this.toast(this.$t('Produit enregistrer avec success'), 'success')
            }
          })
          .catch(error => {

          }).then(() => {
          this.loading = false
        })
      },
      orderImages (form, images) {
        // if (!this.product.id && this.form) return
        // const url = "admin/customer/photo/" + this.customer.id;
        // upload data to the server
        //    this.currentStatus = STATUS_SAVING;
        // this.$emit('input', null);

        // this.formData = null

        // this.loading = true
        axios.post(`/product/order_photos/${this.product.id}`, { images: images.map((e) => e.id) })
          .then(response => {
            // this.product.images.push(response.data)
            // this.product.media = this.product.media || []
            // this.product.media.push(response.data)
            /* if (saved) {
              this.toast(this.$t('Produit enregistrer avec success'), 'success')
            } */

          })
          .catch(error => {

          }).then(() => {
            this.loading = false
          })
      },
      getCities (code) {
        var country = this.getItem(this.countries, 'code', code)
        var _this = this
        // this.order.address.city_id = null;

        if (!country.cities || country.cities.length === 0) {
          this.loading_cities = true
          axios.get('/countries/' + code).then((response) => {
            _this.loading_cities = false
            country.cities = response.data
            Vue.set(_this.countries, _this.getIndex(_this.countries, 'code', code), country)
          })
        }
        ;
        return country.cities || []
      },
      cityChanged (event) {
        // this.relaypoints(0);

      },
      zipChanged (event) {
        // this.relaypoints(0);

      },
      close () {
        $('#paymentbox').modal('hide')
        $('#paymentbox').find('.modal-body').html('')
      },
      getItem (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return items[i]
          }
        }
        return false
      },

      getIndex (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return i
          }
        }
        return -1
      },
    },
  }
</script>
