<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="6"
          xl="6"
        >
          <div class="product-slider">
            <product-media
              v-model="product"
              :height="$vuetify.breakpoint.smAndDown?($vuetify.breakpoint.xsOnly?'400':'400'):'450'"
              width="300"
              thumb-size="70"
              field="large"
              :show-thumbnails="true"
              source="single"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="6"
          xl="6"
        >
          <product-view-summary
            v-model="product"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <slot name="back-button" />
      <v-spacer />
      <v-btn
        v-if="product.active"
        color="default"
        :loading="loading"
        @click="publish"
      >
        {{ $t("Retirer") }}
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="loading"
        @click="publish"
      >
        {{ $t("Publish") }}
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="isSnackbarBottomVisible">
      {{ snackBarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackBarColor"
          text
          v-bind="attrs"
          @click="isSnackbarBottomVisible = false"
        >
          {{ $t("Fermer") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="loading"
      persistent
      width="70"
      class="overflow-hidden transparent"
    >
      <v-card
        width="70"
        height="70"
        class="overflow-hidden"
      >
        <v-row
          no-gutters
          justify="center"
          align="center"
          class="fill-height align-self-center"
        >
          <v-col
            cols="12"
            class="text-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              class="mx-auto"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import { mapGetters, mapActions, mapState } from 'vuex'
  import { i18n } from '../../plugins/i18n'
  import ProductMedia from '../../components/products/ProductMedia.vue'
  import ProductViewSummary from './ProductViewSummary.vue'

  export default {
    components: {
      ProductViewSummary,
      ProductMedia,
    },
    props: {
      value: { type: [Object, Array], default: () => { return { images: [] } } },
    },
    data () {
      return {
        step: 0,
        tab: null,
        showWarning: false,
        productForm: null,
        validAddressForm: false,
        loading: false,
        loading_points: false,
        loading_cities: false,
        create_new: false,
        security_tax: 0,
        commission_minimal: 0,
        formData: null,
        fileList: [],
        donate: 0,
        details: {},
        form: {},
        usercountry: 0,
        tvas: [],
        transporters: [],
        system_transporters: [],
        shipment_methods: [],
        payment_methods: [],
        relay_points: [],
        packages: [],
        images: [],
        package: {},
        errors: {
          firstname: false,
          lastname: false,
          email: false,
          phone: false,
          country: false,
          address: false,
          zip: false,
          city: false,
        },

        errorMessages: { name: 'Est requis' },
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        telRules: [v => !!v || i18n.t('Le numéro de téléphone est requis.')],
        addressRules: [v => !!v || i18n.t('L\'adresse est requise.')],
        introRules: [v => !!v || i18n.t('L\'introduction est requise.')],
        nameRules: [v => !!v || i18n.t('Le Nom du produit est requise.')],
        publicRules: [v => !!v || i18n.t('Le Prix public est requise.')],
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        radios: null,
        radiosTwo: null,
        cart: [
        ],
        product: {
          images: [],
        },
        order: {
          user: {
            country_code: null,
          },
          country_code: null,
          city: null,
          post_code: null,
        },
        addresses: [],
        deliveryaddress: [],
        snackBarMessage: '',
        snackBarColor: null,
        isSnackbarBottomVisible: false,

      }
    },
    computed: {
      ...mapGetters(['getCartProducts', 'unreadNotifications']),
      ...mapState({
        user: 'user',
        salutations: 'salutations',
        countries: 'countries',
        displayNotification: 'display_notification',
      }),
      cartTotal () {
        let total = 0
        this.getCartProducts.forEach(product => {
          total += product.amount * product.qty
        })
        return total
      },
      productImages () {
        return this.product.images.map((e) => { return { ...e, path: e.image } })
      },
    },
    watch: {
      value: {
        handler (val) {
          console.log('change noticed')
          this.product = this.value
        },
        deep: true,
      },
    },
    mounted () {
      // this.getProduct()
    },
    created () {
      this.product = this.value || { images: [] }
    },
    methods: {

      publish () {
        if (this.product.active) {
          this.product.active = false
        } else {
          this.product.active = true
        }
        this.save()

        /* axios.post('/products', this.form).then((res) => {
          this.step = 1
          return true
        }).catch((err) => {
          return false
        }) */
        return true
      },

      save () {
        this.loading = true

        axios({
          method: 'post',
          url: '/products',
          data: { ...this.product },
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).then((response) => {
          if (response.data && response.data.product) {
            var ele = this.processProduct(response.data.product)
            var related_products = ele.related_products || []
            var transp = response.data.product.transporters || []

            this.product = {
              ...ele,
              transporters_ids: transp.map((e) => e.id),
              qty: (this.$store.state.cart.products.find((el) => el.id === ele.id) || { qty: 0 }).qty || 0,
              related_products: related_products.map(el => {
                el.qty = (this.$store.state.cart.products.find((e) => e.id === el.id) || { qty: 0 }).qty || 0
                return this.processProduct(el)
              }),
            }
            this.$emit('input', this.product)
            if (this.product.active) {
              this.toast(this.$t('Produit publier avec success'), 'success')
            } else {
              this.toast(this.$t('Produit retirer avec success'), 'success')
            }
          }
          if (response.data.tvas) {
            this.tvas = response.data.tvas
          }
        }).catch((err) => {
          this.loading = false
          return false
        }).then(() => {
          this.loading = false
        })
        return true
      },
      getProduct () {
        axios
          .get(`/products/${this.$route.params.slug}/edit`)
          .then(response => {
            if (response.data && response.data.product) {
              var ele = this.processProduct(response.data.product)
              var related_products = ele.related_products || []
              var transp = ele.transporters || []

              this.product = {
                ...ele,
                transporters_ids: transp.map((e) => e.id),
                qty: (this.$store.state.cart.products.find((el) => el.id === ele.id) || { qty: 0 }).qty || 0,
                related_products: related_products.map(el => {
                  el.qty = (this.$store.state.cart.products.find((e) => e.id === el.id) || { qty: 0 }).qty || 0
                  return this.processProduct(el)
                }),
              }
              this.images = this.productImages
            }
            if (response.data.tvas) {
              this.tvas = response.data.tvas
            }
          })
          .catch(error => console.log(error)).then(() => {

          })
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },

      getItem (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return items[i]
          }
        }
        return false
      },

      getIndex (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return i
          }
        }
        return -1
      },
    },
  }
</script>
