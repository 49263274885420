<template>
  <div class="chute chute-center">
    <!-- AllCP Info -->
    <div class="allcp-panels fade-onload">
      <v-row>
        <!--        <v-col
          cols="12"
          md="7"
        >
          <div
            id="spy3"
            class="panel"
          >
            <v-card
              id="sortable"
              :loading="loading"
              dense
              tile
            >
              <v-card-title>
                {{ $t("Categorie") }} <v-spacer />
                <v-text-field
                  v-model="search"
                  outlined
                  dense
                  hide-details
                  :placeholder="$t('Rechercher une categorie ...')"
                  class="form-control gui-input"
                  clearable
                  :loading="loading"
                  @click:clear="cancelsearch"
                />
              </v-card-title>
              <v-divider />
              <v-list
                dense
                style="max-height: 500px"
                class="overflow-y-auto"
              >
                <v-list-item-group
                  v-model="selected"
                  color="primary"
                >
                  <template v-for="(cat, index) in categories">
                    <v-list-item
                      dense
                      :id="'prodcat-'+cat.id"
                      :key="cat.id"
                      :value="!cat.children && cat.parent_id?cat.id:null"
                      @click="!cat.children && cat.parent_id?create(cat,index):null"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-action    v-if="!cat.children && cat.parent_id">
                          <v-checkbox

                            :input-value="active"
                            color="primary"

                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content class="dd-handle dd-primary">
                          <v-list-item-title>
                            {{ cat.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div class="d-flex">
                            <v-btn
                              v-if="cat.children"
                              icon
                              :title="$t('aller aux enfants')"

                              @click.stop="children(cat)"
                            >
                              <v-icon color="primary">
                                mdi-arrow-down
                              </v-icon>
                            </v-btn>

                            <v-btn
                              v-if="cat.parent_id"
                              icon
                              :title="$t('retour aux parents')"
                              @click.stop="parents(cat)"
                            >
                              <v-icon color="primary">
                                mdi-arrow-up
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                    <v-divider
                      v-if="index < categories.length - 1"
                      :key="index"
                    />
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>

            &lt;!&ndash; &ndash;&gt;<div
              v-if="ll.pagination > 1"
              class="dataTables_paginate paging_simple_numbers text-right"
            >
              <ul class="pagination">
                <li
                  v-for="(n,index) in ll.pagination"
                  :key="index"
                  :class="'page-item '+ highlight(n) "
                >
                  <a
                    class="page-link"
                    href="#"
                    data-next="1"
                    @click="nextchildren(n)"
                  >{{ n }}</a>
                </li>
              </ul>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <product-form-attribute
            :product="product"
            :attributes="attributes"
            :productattributes="productattributes"
          />
        </v-col>-->

        <v-col
          cols="12"
        >
<!--          <category-picker
            :item="category"
            :show-tool="false"
            @selected="setCategory"
          />-->
          <CategoryPickerDialog @selected="setCategory" :item="category"  :show-tool="false" />
        </v-col>
        <v-col
          cols="12"
        >
          <!--          <v-progress-circular
            v-if="loadingAttributes"
            indeterminate
            color="primary"
          />-->
          <v-row>
            <v-col cols="12" sm="6" md="5">
              <v-select
                v-model="productAttribute.attribute_id"
                :label="$t('Sélectionner un attribut')"
                :items="attributes.filter((e) => !productAttributesIds.includes(e.id))"
                item-text="name"
                item-value="id"
                dense
                outlined
                hide-details
                :loading="loadingAttributes"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                id="text"
                v-model="productAttribute.value"
                outlined
                dense
                autocomplete="off"
                type="text"
                name="text"
                :label="$t('Value')"
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-btn
                depressed
                outlined
                small
                color="primary"
                :loading="loadingAttributes"
                @click="addAttribute"
              >
                {{ $t('Ajouter') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <div class="form-group ">
                <p class="text-14">
                  {{ $t("List des Attributs") }}
                </p>
                <v-simple-table
                  style="max-height: 290px"
                  class="overflow-y-auto"
                >
                  <thead>
                    <tr>
                      <th>{{ $t("Attribut") }}</th>
                      <th>{{ $t("Value") }}</th>
                      <th>{{ $t("action") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(attribute, $index) in product.attributes"
                      :key="$index"
                    >
                      <td>{{ attribute.attribute.name }}</td>
                      <td class="pt-1">
                        {{ product.attributes[$index].value }}
<!--                        <v-text-field
                          v-model="product.attributes[$index].value"
                          outlined
                          :placeholder="$t('Value')"
                          hide-details
                          dense
                        />-->
                      </td>
                      <td>
                        <v-btn
                          icon
                          small
                          @click="product.attributes.splice($index,1);$emit('saved',product)"
                        >
                          <v-icon color="red">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import ProductFormAttribute from './ProductFormAttribute.vue'
  import CategoryPicker from './products/CategoryPicker.vue'
  import CategoryPickerDialog from './products/CategoryPickerDialog.vue'

  var catid = 0
  export default {
    name: 'ProductFormCategory',
    components: { CategoryPickerDialog, CategoryPicker, ProductFormAttribute },
    props: {
      product: {
        type: Object,
        default: () => {},
      },
      showAttribute: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        selected: null,
        category: {},
        el: null,
        loading: false,
        loadingAttributes: false,
        categoriestring: '',
        search: '',
        resultdatas: [],
        categories: [],
        categorytree: [],
        categoryselected: [],
        ll: {},
        currentpage: 1,
        timeout: null,
        productattributes: [],
        attributes: [],
        productAttribute: { attribute_id: null, value: null },
      }
    },
    computed: {
      productAttributesIds () {
        if (this.product.attributes) {
          return this.product.attributes.map((e) => e.attribute_id)
        }
        return []
      },
    },
    watch: {
      search (val) {
        /* if (val.trim().length > 2) { */
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.findcategory()
        }, 1500)
      },
      product (val) {
        console.log(val)
        //this.product = val
        if (this.product.category) {
          this.attributes = this.product.category.attributes
          // this.setCategory(this.product.category)
        }
      },
    },
    mounted () {
      if (this.product.category) {
        if (this.product.category) {
          // this.setCategory(this.product.category)
          this.attributes = this.product.category.attributes
        }
        this.setCategory(this.product.category)
      }
      this.init()
    },
    destroyed () {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
    },
    methods: {
      addAttribute () {
        if (!this.productAttribute || !this.productAttribute.attribute_id) {
          this.toast('Select attribute', 'red')
          return
        }
        if (!this.productAttribute.value) {
          this.toast('Entrer la valeur', 'red')
          return
        }
        this.productAttribute.attribute = this.attributes.find((e) => e.id === this.productAttribute.attribute_id)
        this.product.attributes = this.product.attributes || []
        this.product.attributes.push({
          ...this.productAttribute,
        })
        this.productAttribute = { attribute_id: null, value: null }
        this.$emit('saved', this.product)
      },
      setCategory (cat) {
        if (cat) {
          this.product.category_id = cat.id
          this.product.category = cat
          this.results = []
          // this.categorytree.push(cat);
          this.edit_category = false
          cat.loading = true
          this.loadingAttributes = true
          /**/
          // eslint-disable-next-line no-undef
          axios.get('/categories/datacompletion/' + cat.id + '/' + this.product.id) // ,, {params: {term: this.category_search}}
            .then((response) => {
              this.attributes = response.data.categoryAttributes
              // this.productattributes = response.data.productAttributes
            }).then(() => {
              this.loadingAttributes = false
              cat.loading = false
            })
        } else {
          this.product.category_id = null
          this.product.category = null
          this.product.attributes = []
          this.attributes = []
        }
      },
      cancelsearch () {
        this.search = ''
        this.resultdatas = []
        this.init()
      },
      findcategory () {
        if (this.search.length >= 3) {
          // $("#box-loader").show();
          if (this.resultdatas.length) {
            this.categories = this.filterrow(this.search, this.resultdatas)
            return
          }
          // else
          this.loading = true
          // eslint-disable-next-line no-undef
          axios.get('/categories/find/' + this.search)//
            .then((response) => {
              this.loading = false
              console.log(response)
              this.categorytree = []
              this.resultdatas = response.data
              this.categories = response.data
            }).catch(function (error) {
              console.log('some error ', error)
            })
        } else {
          /* $('#productselected').html('') */
          this.categories = []
          this.resultdatas = []
          this.init()
        }
      },

      filterrow (value, dataarray) {
        var filter; var filtered = []; var tr; var td; var i; var data

        console.log(dataarray)
        filter = value.toUpperCase()

        for (i = 0; i < dataarray.length; i++) {
          data = dataarray[i]
          if (data.name.toUpperCase().indexOf(filter) > -1) {
            filtered.push(data)
          }
        }
        return filtered
      },

      edit (el) {
        // this.el =el;
        this.category = el
      },
      parents (el) {
        this.loading = true
        // eslint-disable-next-line no-undef
        axios.get('/categories/backparent/' + el.parent_id)
          .then((response) => {
            console.log(response)
            this.loading = false
            this.categories = response.data.categories.map((e) => { return { ...e, loading: false } })
            // this.category = response.data.category
            this.categorytree = response.data.categorytree.map((e) => { return { ...e, loading: false } })
          }).catch(function (error) {
            console.log(error)
          })
      },

      children (el) {
        this.loading = true
        catid = el.id
        console.log(el)
        // eslint-disable-next-line no-undef
        axios.get('/categories/getchildren/' + el.id)
          .then((response) => {
            console.log(response)
            this.loading = false
            this.categories = response.data.categories
            // this.category = response.data.category
            this.categorytree = response.data.categorytree
          }).catch(function (error) {
            console.log(error)
          })
      },
      highlight (next) {
        return (this.currentpage === next) ? 'active' : ''
      },
      nextchildren (next) {
        // this.category =el;
        // this.categorytree.push(el);
        this.currentpage = next
        this.loading = true
        axios.get('/categories/nextchildren/' + catid + '?next=' + next)
          .then((response) => {
            this.loading = false
            console.log(response)
            this.ll = response.data.ll

            this.categories = this.ll.listEntity
          }).catch(function (error) {
            console.log(error)
          })
      },
      _delete (el, index) {
        // this.el =el;

        if (!confirm('Voulez-vous Supprimer?')) return false
        this.categoryselected.splice(index, 1)
        // axios.get(window.axios.defaults.baseURL + "/categories-delete/" + el.id)
        //     .then(function (response) {
        //         console.log(response);
        //         this.categories.splice(index, 1);
        //     }).catch(function (error) {
        //     console.log(error);
        // });
      },
      create (el, index) {
        this.category = el
        this.selected = el.id
        this.$emit('categoryselected', el)
        this.setCategory(el)
        // this.categoryselected.push(el);
      },
      init () {
        this.loading = true
        axios.get('/categories/initdata/' + this.product.category_id || 0)
          .then((response) => {
            console.log(response)
            this.categories = response.data.categories
            this.category = response.data.category
            this.categorytree = response.data.categorytree
            this.ll = response.data.ll
            catid = this.category.id
            this.loading = false
            this.selected = this.categories.findIndex((ele) => ele.id === this.category.id)
            // gsap.to("#sortable", {duration: .5, scrollTo: "#prodcat-"+this.category.id});
          }).catch(function (error) {
            console.log(error)
          }).then(() => {
            this.loading = false
          })
      },

    },
  }
</script>
