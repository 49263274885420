<template>
  <!-- card-hover-shadow  -->
  <base-card :loading="loading">
    <v-progress-circular
      v-if="parent != null && loading"
      class="mx-auto"
      indeterminate
      color="primary"
    />
    <v-card-title v-if="parent == null && showTool">
      {{ $t("Categorie") }}
      <v-spacer />
      <v-btn
        class="mt-4"
        x-small
        color="primary"
        text
        @click="$emit('closeDialog')"
      >
        Fermer
      </v-btn>
    </v-card-title>
    <v-divider />

    <v-card-text :class="{'pa-0 pl-2': parent != null }">
      <v-text-field
        v-if="parent == null || parent.sub_categories_count > 10"
        v-model="search"
        outlined
        dense
        hide-details
        :placeholder="$t('Rechercher une categorie ...')"
        class="form-control gui-input"
        clearable
        :loading="loading"
        @click:clear="cancelsearch"
      />
      <div
        style="max-height: 400px;"
        class="overflow-y-auto"
      >
        <div v-for="cat in categories.filter((e) => !search || e.name.toLowerCase().includes(search.toString()))">
          <div class="display-flex d-flex py-1 justify-space-between">
            <span :class="{'primary--text font-weight-bold' : category != null && category.id === cat.id}">{{ cat.name }}</span>
            <div class="d-flex">
              <v-btn
                color="primary"
                x-small
                :outlined="!(category != null && category.id === cat.id)"
                @click.stop="category = cat;setCategory(cat)"
              >
                {{ $t("Sélectionner") }}
              </v-btn>
              <v-btn
                v-if="cat.sub_categories_count > 0"
                outlined
                color="secondary"
                depressed
                class="ml-2"
                x-small
                @click.stop="cat.showChildren = !cat.showChildren"
              >
                {{ !cat.showChildren ? $t('Voir plus') : $t('Cacher') }}
              </v-btn>
            </div>
          </div>
          <v-expand-transition>
            <div v-if="cat.showChildren">
              <CategoryPicker
                :parent="cat"
                :item="category"
                @selected="setCategory"
              />
            </div>
          </v-expand-transition>

          <v-divider />
        </div>
      </div>
    </v-card-text>
    <!--    <v-card-actions>
      <v-btn
        text
        @click="dialog=false"
      >
        {{ $t("Annuler") }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        :loading="loading"
        @click="save"
      >
        {{ $t("Confirmer") }}
      </v-btn>
    </v-card-actions>-->
  </base-card>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import VendorLink from '../VendorLink'
  import AddToCartButton from './AddToCartButton'

  export default {
    name: 'CategoryPicker',
    components: {},
    props: {
      item: {
        type: Object,
        default: () => {},
      },
      parent: {
        type: Object,
      },
      showTool: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        dialog: false,
        loading: false,
        category: null,
        search: '',
        categories: [],
        timeout: null,
      }
    },
    computed: {
    },
    watch: {
      search () {
        if (this.search) {
          if (this.timeout) {
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(() => {
            this.getCategories()
          }, 1500)
        }
      },
      item (val) {
        this.category = this.item
      },
    },
    mounted () {
      this.category = this.item
      this.getCategories()
    },
    created () {
      this.category = this.item
    },
    destroyed () {
      clearTimeout(this.timeout)
    },
    methods: {
      setCategory (category) {
        this.$emit('selected', category)
      },
      save () {
        this.dialog = false
        this.$emit('selected', this.category)
      },
      cancelsearch () {
        this.search = ''
        this.resultdatas = []
      },
      getCategories () {
        if (this.search.length < 3 || !this.loading) {
          this.loading = true
          axios.get('/categories/search', { params: { term: this.search, per_page: 50, category: this.parent != null ? this.parent.id : null } }).then((response) => {
            this.loading = false

            if (response.data.data && response.data.data.length) {
              for (let i = 0; i <= response.data.data.length; i++) {
                const d = response.data.data[i]
                if (d) {
                  const index = this.categories.findIndex((e) => e.id === d.id)
                  if (index < 0) {
                    this.categories.push({ ...d, showChildren: false })
                  }
                }
              }
            }
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>

    .br-t-8 {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  .card-hover {
    &:hover {
      .card-hover-icon {
        display: block;
      }
    }
    .card-hover-icon {
      display: none;
    }
  }

</style>
