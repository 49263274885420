<template>
  <div class="pt-2 pb-2" v-html="product.attribute_description">
<!--    <div
      v-for="attribute in product.attributes || []"
      :key="attribute.key"
      class="d-flex mb-4"
    >
      <p class="grey&#45;&#45;text text&#45;&#45;grey-lighten-1 me-3 mb-0 text-uppercase">
        {{ attribute.key }}:
      </p>
      <p class="font-600 mb-0 text-uppercase">
        {{ attribute.values.map(ele => ele.value).join(' . ') }}
      </p>
    </div>-->
  </div>
</template>

<script>

  import { mapActions } from 'vuex'

  export default {
    name: 'ProductAttributes',
    directives: {
    },
    components: {
    },
    props: {
      product: {
        type: Object,
        default: () => {},
      },
    },
    data: () => ({
      singleProductData: {},
    }),
    computed: {

    },
    created () {
      this.singleProductData = this.product
    },
    methods: {
      ...mapActions(['addCart', 'removeCart']),
      addCartx (data) {
        this.addCart(data)
      },
      removeCartx (data) {
        this.removeCart(data)
      },
    },
  }
</script>
