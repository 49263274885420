<template>
  <!-- card-hover-shadow  -->
  <v-dialog
    v-model="dialog"
    max-width="700px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-content>
          <v-list-item-title>{{ $t("Categorie") }}</v-list-item-title>
          <v-list-item-title v-if="category && category.name">{{ category.name }}</v-list-item-title>
          <v-list-item-title v-if="!category">{{ $t("Sélectionner une catégorie") }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
    <CategoryPicker :item="category" @selected="setCategory" @closeDialog="closeDialog" />
  </v-dialog>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import VendorLink from '../VendorLink'
  import AddToCartButton from './AddToCartButton'
  import CategoryPicker from './CategoryPicker.vue'

  export default {
    name: 'CategoryPickerDialog',
    components: { CategoryPicker },
    props: {
      item: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        dialog: false,
        loading: false,
        category: null,
        search: '',
      }
    },
    computed: {
    },
    watch: {
      item (val) {
        this.category = this.item
      },
    },
    mounted () {
      this.category = this.item
    },
    created () {
      this.category = this.item
    },
    methods: {
      closeDialog () {
        this.dialog = false
      },
      setCategory (category) {
        this.category = category
        this.$emit('selected', this.category)
        console.log('selected',this.category)
      },
    },
  }
</script>
<style lang="scss" scoped>

    .br-t-8 {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  .card-hover {
    &:hover {
      .card-hover-icon {
        display: block;
      }
    }
    .card-hover-icon {
      display: none;
    }
  }

</style>
